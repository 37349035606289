import React, { useEffect, useContext, useState, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import recordImg from '../assets/images/record.png';
import { useParams, useSearchParams } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import { Centrifuge } from 'centrifuge';
import aspbackend from '../api/aspbackend';
import SocialNetworksArtist from './SocialNetworksArtist';
import History from './History';
import HistorySYB from './HistorySYB';
import UpcomingEvent from './UpcomingEvent';
import Footer from './Footer';
import Banner from './Banner';
import '../assets/styles/NowPlaying.css';
import { Context as GeneralContext } from '../context/GeneralContext';

polyfillCountryFlagEmojis();

const NowPlaying = () => {
  let { siteID } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentArtist, setCurrentArtist] = useState(null);
  const [history, setHistory] = useState(null);
  const [centrifugeObj, _setCentrifugeObj] = useState(null);
  const [localPlayerStatus, setLocalPlayerStatus] = useState('active');

  const centrifugeObjRef = useRef(centrifugeObj);
  const setCentrifugeObj = (val) => {
    centrifugeObjRef.current = val;
    _setCentrifugeObj(val);
  };

  const [activeSlide, setActiveSlide] = useState(0);
  const [event, setEvent] = useState(null);

  const {
    setSite,
    state: { site },
  } = useContext(GeneralContext);

  const wssSubscribe = async () => {
    await aspbackend.get(`token`).then(async (response) => {
      // console.log('subscribing to', siteID);
      // Use WebSocket transport endpoint.
      const centrifuge = new Centrifuge(
        'wss://centrifugo.aquisuenaperu.com/connection/websocket',
        {
          token: response.data,
        },
      );
      // console.log('connecting to', centrifugeObjRef);
      setCentrifugeObj(centrifuge);

      // Allocate Subscription to a channel.
      // console.log('subscribing to', `now_playing_${siteID}`);
      const sub = centrifuge.newSubscription(`site_update_${siteID}`);

      // React on `news` channel real-time publications.
      sub.on('publication', function (ctx) {
        if (ctx?.data?.site) {
          setSite(ctx.data.site);
        }
        if (ctx?.data?.artist) {
          setCurrentArtist(ctx.data.artist);
        }
      });

      // Trigger subscribe process.
      sub.subscribe();

      // Trigger actual connection establishement.
      centrifuge.connect();

      // centrifuge.on('connecting', function(ctx) {
      //   console.log('connecting', ctx);
      // });

      // centrifuge.on('connected', function(ctx) {
      //   console.log('connected', ctx);
      // });

      // centrifuge.on('disconnected', function(ctx) {
      //   console.log('disconnected', ctx);
      // });
    });
  };

  // const startTimer = () => {
  //   getInfo(true);
  //   const _intervalID = setInterval(() => {
  //     getInfo(false);
  //   }, 2500);
  //   clearInterval(_intervalID - 1);
  // };

  const getInfo = async (initial) => {
    await aspbackend
      .get(`site/${siteID}?includeBusiness=1`)
      .then(async (response) => {
        setSite(response.data);
        let artistName =
          response.data?.currentTrack?.artists.length === 1 ||
          (response.data?.currentTrack?.artists.length > 1 &&
            !response.data?.currentTrack?.artists[0].name.includes(' & '))
            ? response.data?.currentTrack?.artists[0].name.normalize('NFD')
            : response.data?.currentTrack?.artists[1].name.normalize('NFD');
        if (initial) {
          const scan = searchParams.get('scan');
          // delete param
          searchParams.delete('scan');
          setSearchParams(searchParams);
          // store scan in stats
          if (scan === 'true') {
            const statsObject = {
              siteID,
              trackName:
                response.data?.sourceType === 'soundtrack'
                  ? response.data?.currentTrack?.title
                  : response.data?.currentTrack?.name,
              artistName: artistName,
              date: Date.now(),
            };
            // console.log('scanned with', statsObject);
            await aspbackend.post('stats', statsObject, {
              headers: { 'content-type': 'application/json' },
            });
          }
        }
        await aspbackend
          .get(`artist?name=${encodeURIComponent(artistName)}`)
          .then((response) => {
            setCurrentArtist(response.data);
          });
      });
  };

  const onFocus = () => {
    getInfo(false);
    // console.log('reconnecting if object found:', centrifugeObjRef);
    if (centrifugeObjRef.current) {
      centrifugeObjRef.current.connect();
    }
  };

  const onBlur = () => {
    // console.log('disconnecting if object found:', centrifugeObjRef);
    if (centrifugeObjRef.current) {
      centrifugeObjRef.current.disconnect();
    }
  };

  useEffect(() => {
    wssSubscribe();
    getInfo(true);
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    // startTimer();
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  useEffect(() => {
    setHistory(site?.recentlyPlayed);
    setEvent(currentArtist?.upcomingEvent);
    setActiveSlide(0);
    setLocalPlayerStatus(
      site && site.playerStatus !== 'playing' ? 'inactive' : 'active',
    );
  }, [site?.currentTrack, currentArtist?.name, site?.playerStatus]);

  const renderThumbs = () => {};

  const carouselItemFlag = (isLocal) => {
    return isLocal ? (
      <ReactCountryFlag className="flag" countryCode="PE" />
    ) : null;
  };

  const carouselItemYear = (date) => {
    return date ? <div className="year">{date.slice(0, 4)}</div> : null;
  };

  const carouselItems = [
    <div key={1}>
      {carouselItemFlag(site?.currentTrack?.isLocal)}
      {site?.currentTrack?.album
        ? carouselItemYear(site?.currentTrack?.album?.releaseDate?.timestamp)
        : null}
      <LazyLoadImage
        alt={'album cover of this song'}
        effect="blur"
        placeholder={
          <img src={recordImg} className="nowPlayingImageSlide" width={500} />
        }
        src={
          site?.sourceType === 'soundtrack'
            ? site?.currentTrack?.album?.display?.image?.sizes?.teaser
            : site?.currentTrack?.image
        }
        className="nowPlayingImageSlide"
      />
    </div>,
    site?.currentTrack?.isLocal && currentArtist?.bio ? (
      <div key={2} className="nowPlayingTextSlide">
        {carouselItemFlag(site?.currentTrack?.isLocal)}
        {site?.currentTrack?.album
          ? carouselItemYear(site?.currentTrack?.album?.releaseDate?.timestamp)
          : null}
        <p>{currentArtist?.bio}</p>
      </div>
    ) : null,
    site?.currentTrack?.isLocal && currentArtist?.image ? (
      <div key={3}>
        {carouselItemFlag(site?.currentTrack?.isLocal)}
        {site?.currentTrack?.album
          ? carouselItemYear(site?.currentTrack?.album?.releaseDate?.timestamp)
          : null}
        <img
          src={currentArtist?.image}
          className="nowPlayingImageSlide"
          alt={'artist'}
        />
      </div>
    ) : null,
  ];

  const dateInPast = (date) =>
    new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);

  console.log(site);
  return (
    <div className="nowPlayingDiv">
      <div className="introText">
        <p style={{ textAlign: 'center', fontSize: '1rem' }}>
          En{' '}
          <a
            href={site?.siteDetails?.mainURL || site?.business?.mainURL}
            target="_blank"
            rel={'noreferrer'}>
            {site?.siteDetails?.name}
          </a>{' '}
          aportamos a la industria musical.
          <br />
          Ahora suena:
        </p>
      </div>
      {localPlayerStatus && localPlayerStatus === 'inactive' ? (
        <div className="nowPlayingTextDiv">
          <p className="nowPlayingTextArtist">· AVISO ·</p>
          <p className="nowPlayingTextSong">
            · Reproductor apagado o sin Internet ·
          </p>
        </div>
      ) : (
        <div className="nowPlayingTextDiv">
          <p className="nowPlayingTextArtist">
            {site?.currentTrack?.artists.length === 1
              ? site?.currentTrack?.artists[0].name
              : site?.currentTrack?.artists
                  .filter((artist) => !artist.name.includes(' & '))
                  .map(
                    (artist, index, artists) =>
                      artist.name +
                      (artists.length > 1 && index + 1 !== artists.length
                        ? ', '
                        : ''),
                  )}
          </p>
          <p className="nowPlayingTextSong">
            {site?.sourceType === 'soundtrack'
              ? site?.currentTrack?.title
              : site?.currentTrack?.name}
          </p>
        </div>
      )}

      {localPlayerStatus && localPlayerStatus === 'inactive' ? (
        <>
          <img
            src={'https://aquisuenaperu.com/images/danger.png'}
            className="nowPlayingImageSlide"
            alt={'is off'}
          />
          <p
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              marginTop: '1rem',
            }}>
            Si el local está en horas de operación, contacta con su
            administración.
          </p>
        </>
      ) : (
        <>
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            emulateTouch={true}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            renderThumbs={renderThumbs}
            showStatus={false}
            onChange={setActiveSlide}
            selectedItem={activeSlide}>
            {carouselItems.filter((item) => item !== null)}
          </Carousel>
          {currentArtist?.name ? (
            <>
              <p style={{ textAlign: 'center', marginTop: '1rem' }}>
                Visita las redes de este artista:
              </p>
              <SocialNetworksArtist currentArtist={currentArtist} />
            </>
          ) : null}
          {site?.banner ? (
            <Banner image={site?.banner} url={site?.bannerURL} />
          ) : null}
        </>
      )}

      {history ? (
        <>
          <p style={{ marginTop: '1rem' }}>Antes sonó:</p>
          {site?.sourceType === 'soundtrack' ? (
            <HistorySYB history={history} />
          ) : (
            <History history={history} />
          )}
        </>
      ) : null}
      {event && !dateInPast(event.date) ? (
        <UpcomingEvent event={event} />
      ) : null}
      <Footer />
    </div>
  );
};

export default NowPlaying;
